/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:33aa2b8a-5edb-419d-ac1a-ca095dbda853",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_iZRc6ME0Y",
    "aws_user_pools_web_client_id": "220b6rlrdiv3s9e6m7qj1qupim",
    "oauth": {},
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    }
};


export default awsmobile;
